import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyDFzVYgvikoauBFPZercjCDfA87mxIkFiQ",

  authDomain: "love-news-3b4ca.firebaseapp.com",

  databaseURL: "https://love-news-3b4ca-default-rtdb.firebaseio.com",

  projectId: "love-news-3b4ca",

  storageBucket: "love-news-3b4ca.appspot.com",

  messagingSenderId: "440307782542",

  appId: "1:440307782542:web:0bfc27161a5661fa6d8adf",

  measurementId: "G-J4N9SS0E52"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
